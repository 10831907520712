<template>
  <v-container
    id="about"
    tag="section"
  >
    <base-subheading>About Us</base-subheading>

    <p>
      We are a Sikh charity set up to serve the world.
    </p>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
  }
</script>
